import styled, {css} from "styled-components";
import {Link} from "react-scroll";

// icons
import RightArrow from "assets/img/svg/arrow-right.svg";
import ArrowGold from "assets/img/svg/arrow-gold.svg";

const defaultStyles = css`
    font-size: 14px;
    background-color: ${({theme}) => theme.colors.primary};
    transition: 0.2s background-color, border 0.2s;
    padding: 14px 24px;
    color: ${({theme}) => theme.colors.black};
    font-weight: ${({theme}) => theme.weights.less_bold};
    border: 2px solid ${({theme}) => theme.colors.primary};
    outline: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1;

    &.right_arrow {
        padding-right: 80px;
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 24px;
            width: 28px;
            height: 15px;
            background-image: url(${RightArrow});
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &.dark {
        background-color: ${({theme}) => theme.colors.black};
        border-color: ${({theme}) => theme.colors.black};
        color: ${({theme}) => theme.colors.primary};

        &::after {
            background-image: url(${ArrowGold});
        }

        &:hover {
            background-color: ${({theme}) => theme.colors.section_bg};
            border-color: ${({theme}) => theme.colors.section_bg};
        }
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.primary_hover};
        border-color: ${({theme}) => theme.colors.primary_hover};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 10px 36px;
        font-size: 12px;

        &.right_arrow {
            padding-right: 72px;

            &::after {
                height: 10px;
                right: 18px;
            }
        }
    }
`;

export const InsideLink = styled(Link)`
    ${defaultStyles}
`;

export const OutsideLink = styled.a`
    ${defaultStyles}
`;
export const Button = styled.button`
    ${defaultStyles}
`;

export const StyledAnchorLink = styled(Link)`
    ${defaultStyles}
`;
