import React from "react";
import {Button, InsideLink, OutsideLink} from "./styles";

export const PrimaryButton = ({to, href, type, children}) => {
  if (to) {
    return (
      <InsideLink className={type} to={to} smooth>
        {children}
      </InsideLink>
    );
  } else if (href) {
    return (
      <OutsideLink className={type} href={href}>
        {children}
      </OutsideLink>
    );
  } else {
    return <Button className={type}>{children}</Button>;
  }
};
